/*
com = change on mobile.
used for example with the flex-row class to specify if it should change to flex-direction: column for small width devices.
*/

html {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
    scroll-padding-top: 4em;
    overflow-x: hidden;
    scrollbar-width: none;
}

::-webkit-scrollbar {
    display: none;
}

body {
    height: 100%;
    width: 100%;
    margin: 0 0;
    background: rgb(27,27,27);
    /* background-image: url("../content/wp5038731-black-anime-girl-4k-wallpapers.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed; */
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    cursor: default;
}

#root {
    min-height: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

.info {
    font-size: 1.3em;
    line-height: 1.3em;
    padding: 0.6em 0.6em;
    color: rgb(255, 196, 0);
    border: solid rgb(255, 162, 0) 0.15em;
    border-radius: 0.8em;
    box-shadow: 0px 0px 0.65em 0.15em rgb(255, 217, 0);
    text-align: center;
    margin-top: 1.8em;
}

.flex-row, .flex-row-com {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.flex-row-start {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.flex-row-end {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}

.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.full-width {
    width: 100%;
}

::selection {
    color: white;
    background: red;
}

ul {
    padding: 0;
}

button, .btn {
    background-color: rgb(65, 65, 65);
    color: aqua;
    border-radius: 1em;
    border: none;
    margin: 2em 0;
    transition-duration: 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

/* button:focus, */
button:hover, .btn:hover {
    box-shadow: 0 7px 14px -7.5px rgb(65, 65, 65);
    transform: translateY(-0.8em);
}

button h1, .btn h1 {
    width:fit-content;
    text-align: center;
}

.login_content {
    padding: 4px 40px;
    margin: 0;
    display: flex;
    align-items: center;
}

button svg, .btn svg {
    padding-right: 1em;
}

.content {
    color: white;
    padding: 4.1em;
	min-height: 100%;
}

.description {
    color: rgb(231, 231, 231);
    font-size: 92%;
    font-style: italic;
    max-width: 60%;
    width: fit-content;
    background-color: rgb(52,52,52);
    border: solid rgb(41, 41, 41) 5px;
    border-radius: 1.3em;
    box-shadow: 0px 0px 13px 3px rgb(231, 231, 231);
    padding: 8px 8px;
    text-align: justify;
}

.feature {
    color: rgb(231, 231, 231);
    font-size: 92%;
    font-style: italic;
    width: 100%;
    height: 14em;
    background-color: #E80C7AA8;
    border-radius: 1.3em;
    padding: 8px 0;
    text-align: justify;
    margin: 3em 0;
    display: flex;
}

.feature-width-handler{
    width: 42em;
    transition-property: width;
    transition-duration: 1.2s;
    transition-timing-function: ease;
}

.feature-width-handler:hover{
    width: 50em;
}

.feature-start {
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 13px 16px 20px 5px #cf0a6c5e;
}

.feature-end {
    flex-direction: row-reverse;
    justify-content: space-between;
    box-shadow: -13px 16px 20px 5px #cf0a6c5e;
}

.feature-description {
    width: 28em;
    margin: 0 1em;
}

.feature-description h3{
    color: #23D0E8;
}

.feature-image{
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1.2s;
    transition-timing-function: ease-in-out;
}

.feature-start .feature-image{
    float: right;
}

.feature-end .feature-image{
    float: left;
}

.feature:hover .feature-image{
    opacity: 1;
}

.feature-container{
    padding: 0;
    margin: 0;
}

.bannerContainer{
    margin-bottom: 5em;
}

.banner{
    width: 85vw;
    margin-bottom: 3em;
}

h1 {
    width:fit-content;
    font-size: 3em;
    text-align: center;
}

.margin-0 {
    margin: 0;
}

.margin-top-0 {
    margin-top: 0;
}

.margin-bot-0 {
    margin-bottom: 0;
}

#titleafter {
    font-size: 1em;
    font-style: oblique;
    color:darkgray;
}

#gA76Nhfgx7dsq {
    cursor: url("../../public/favicon.ico"), default;
}

@media screen and (max-width: 980px) {
    .flex-row-com {
        flex-direction: column;
        align-items: center;
    }

    .flex-row-start, .flex-row-end {
        justify-content: space-evenly;
        align-items: center;
    }

    .feature {
        height: 20vw;
    }

    .feature-width-handler {
        width: 80vw;
    }

    .feature-width-handler:hover {
        width: 80vw;
    }

    .feature-description {
        width: auto;
        overflow-y: auto;
        scrollbar-width: none;
    }

    .feature-image {
        opacity: 1;
    }
}